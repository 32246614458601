.Confirmation {
  text-align: center;
  padding: 32px 20px;
}

.Confirmation-image {
  margin: 0 auto 32px;
  width: 36px;
  height: 36px;
  color: var(--accent-color);
}

@media only screen and (max-width: 1024px) {
  .Confirmation {
    padding: 0;
    margin-bottom: 32px;
  }

  .Confirmation-image {
    margin-bottom: 24px;
  }
}
