.Form-fieldset {
  border-width: 0;
  margin: 0;
  min-inline-size: auto;
  padding: 0 0 1em 0;
}

.Form-fieldset::after {
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #ccc;
}

.Form-legend {
  color: var(--form-header-color);
  font-weight: bold;
  margin: 0 0 0.5em 0;
  font-size: 1em;
}

.Form-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Form-fields .InputText {
  flex-basis: 100%;
}

.Form-fieldset--account .Form-fields .InputText {
  flex-basis: calc(50% - 1em);
}

.Form-fields .InputText--state {
  margin-right: 1em;
}

.Form .DocumentUpload {
  margin-bottom: 1.5em;
}

.Form-label {
  margin-bottom: 1.5em;
}

.Form .Tooltip {
  margin-left: 5px;
}

.Form-progress-list {
  padding: 0;
  list-style: none;
}

.Form-submit {
  align-items: center;
  background-color: var(--button-background-color);
  border: 2px solid transparent;
  border-radius: var(--button-border-radius);
  color: var(--button-color);
  cursor: pointer;
  display: flex;
  font-size: var(--button-font-size);
  font-weight: bold;
  justify-content: center;
  height: 48px;
  padding: 0;
  width: 100%;
}

.Form-privacy-policy {
  margin-bottom: 1em;
  display: flex;
  align-items: flex-start;
}

.Form-privacy-policy input {
  margin: 4px 1em 0 0;
  vertical-align: middle;
  cursor: pointer;
  flex: 1 0 auto;
}

.Form-privacy-policy--error {
  color: var(--error-color-text);
}

@media (max-width: 1024px) {
  .Form {
    overflow: hidden;
  }

  .Form-fields {
    flex-direction: column;
  }

  .Form-fieldset::after {
    margin: 0 -16px;
    width: calc(100% + 32px);
  }

  .Form-submit {
    font-size: var(--button-font-size-mobile);
  }
}
