.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.App-header {
  color: var(--oem-main-color);
  text-align: center;
}

.App-main {
  margin: 0 auto;
  max-width: 1024px;
  padding: 41px 44px;
}

.App--compact .App-header {
  display: none;
}

.App--compact .App-main {
  padding: 24px;
}

@media (max-width: 1024px) {
  .App-main {
    padding: 32px 16px 48px;
  }

  .App--compact .App-main {
    padding: 0;
    padding-right: 16px;
  }
}
