.TopBanner {
  background: var(--banner-background-color) no-repeat;
  border-bottom: var(--banner-bottom-border) solid var(--banner-bottom-border-color);
  color: var(--banner-color);
  overflow: hidden;
  position: relative;
}

.TopBanner * {
  position: relative;
  z-index: 1;
}

.TopBanner-inner {
  align-items: var(--banner-content-alignment);
  background: transparent no-repeat;
  background-image: var(--banner-background-image);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 44px;
  width: 100%;
}

.TopBanner-logo {
  display: block;
  margin: 44px auto 24px;
  max-height: 30px;
  object-fit: contain;
}

.TopBanner-content {
  max-width: 536px;
  padding: 0 24px;
  text-align: center;
}

.TopBanner h1 {
  font-size: var(--banner-header-font-size);
  line-height: 1.1em;
  margin: 0 0 24px;
}

.TopBanner p {
  line-height: 1.1em;
  margin: 0 auto;
  font-size: var(--banner-font-size);
  margin-bottom: 36px;
}

.Page--Confirmation .TopBanner {
  background: var(--banner-confirmation-background-color);
  max-height: 108px;
}

.Page--Confirmation .TopBanner-inner {
  align-items: center;
  background-image: none;
}

.Page--Confirmation .TopBanner-logo {
  margin: 39px 0;
}

.Page--Confirmation .TopBanner h1,
.Page--Confirmation .TopBanner p {
  display: none;
}

@media (max-width: 1024px) {
  .TopBanner {
    background-image: var(--banner-background-image-mobile);
    background-position: bottom;
    border-bottom-width: 0;
  }

  .Page--Confirmation .TopBanner {
    background: var(--banner-confirmation-background-color);
    border-bottom-width: var(--banner-bottom-border);
  }

  .TopBanner-inner {
    align-items: center;
    background-image: none;
    padding: 0 16px;
  }

  .TopBanner-logo {
    margin: 40px auto 20px;
  }

  .TopBanner-content {
    max-width: initial;
    padding: 0;
  }

  .TopBanner h1 {
    font-size: var(--banner-header-font-size-mobile);
    margin-bottom: 12px;
  }

  .TopBanner p {
    font-size: var(--banner-font-size-mobile);
  }
}
