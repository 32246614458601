.Tooltip {
  --content-position: calc(100% + 10px);
  --content-transition: opacity 0.3s linear;

  position: relative;
}

.Tooltip-content {
  background-color: var(--accent-color);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  opacity: 0;
  padding: 16px;
  pointer-events: none;
  position: absolute;
  transition: var(--content-transition);
  white-space: nowrap;
  z-index: 1;
}

.Tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 9px solid transparent;
}

.Tooltip-content ul {
  padding: 0 20px;
  margin: 0;
}

.Tooltip-position--top {
  bottom: var(--content-position);
  left: 50%;
  transform: translateX(-50%);
}

.Tooltip-arrow.Tooltip-position--top {
  border-top-color: var(--accent-color);
  top: 100%;
}

.Tooltip-position--bottom {
  top: var(--content-position);
  left: 50%;
  transform: translateX(-50%);
}

.Tooltip-arrow.Tooltip-position--bottom {
  top: auto;
  border-bottom-color: var(--accent-color);
  bottom: 100%;
}

.Tooltip-trigger {
  cursor: pointer;
  width: 16px;
  height: 16px;
  font-size: 14px;
  background-color: var(--accent-color);
  border-radius: 50%;
  color: var(--form-browse-button-background-color);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
}

.Tooltip--is-visible {
  opacity: 1;
  z-index: 9;
  visibility: visible;
  pointer-events: auto;
}
