.Home {
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.Home-form {
  border-radius: 6px;
  border: solid 2px var(--form-main-border-color);
  color: var(--form-main-color);
  flex: 1 0 auto;
  font-size: var(--form-main-font-size);
  line-height: 1.47;
  margin-right: 24px;
  padding: 24px;
  width: 536px;
}

.Home-form h2 {
  color: var(--form-header-color);
  font-size: var(--form-header-font-size);
  margin: 0;
  margin-bottom: 0.3em;
}

.Home-form p {
  margin: 0 0 1.5em 0;
}

.Home-rail {
  width: 376px;
}

.Home-rail-content {
  background-color: var(--right-hand-rail-background-color);
  border-radius: var(--right-hand-rail-border-radius);
  color: var(--right-hand-rail-color);
  font-size: var(--right-hand-rail-font-size);
  line-height: 1.4em;
  margin-bottom: 32px;
  padding: 24px;
}

.Home-rail-content--mobile,
.Home-rail-content--extra-text {
  display: none;
}

.Page--Confirmation .Home-rail-content {
  margin-bottom: 0;
  height: 100%;
}

.Home-rail-content h2 {
  color: var(--right-hand-rail-color);
  font-size: var(--right-hand-rail-header-font-size);
  line-height: 1.2em;
  margin-top: 0;
}

.Home-rail-content ul {
  padding-left: 20px;
}

.Home-rail-content ul li {
  margin: 6px 0;
}

.Home-rail-content-toggle {
  display: none;
  margin: 24px auto 0;
  text-transform: uppercase;
  border: 0;
  font-size: 14px;
  line-height: 1.14;
  text-align: center;
  font-weight: 500;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  color: var(--url-color);
  outline-color: var(--url-color);
}

.Home-rail-content-toggle::after {
  content: "";
  border-style: solid;
  border-width: 2px 2px 0 0;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -2px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 8px;
  margin-left: 8px;
  border-color: var(--url-color);
}

.Home-rail-content-toggle.expanded::after {
  top: 2px;
  transform: rotate(-45deg);
}

.Home-rail-content-toggle span:last-child {
  display: none;
}

.Home-rail-content-toggle.expanded span:last-child {
  display: initial;
}

.Home-rail-content-toggle.expanded span:first-child {
  display: none;
}

.NotificationWrapper {
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 10;
}

.NotificationWrapper .ErrorMessage {
  margin: 0;
}

@media (max-width: 1024px) {
  .Home {
    flex-direction: column-reverse;
  }

  .Page--Confirmation .Home {
    flex-direction: column;
  }

  .Home-form {
    border: none;
    margin-right: 0;
    padding: 0;
    width: auto;
    font-size: var(--form-main-font-size-mobile);
  }

  .Home-form h2 {
    font-size: var(--form-header-font-size-mobile);
  }

  .Home-rail {
    margin-bottom: 20px;
    width: auto;
  }

  .Home-rail-content {
    background-color: inherit;
    color: inherit;
    padding: 0;
    margin-bottom: 0;
    font-size: var(--right-hand-rail-font-size-mobile);
  }

  .Home-rail-content--desktop {
    display: none;
  }

  .Home-rail-content--mobile {
    display: block;
  }

  .Home-rail-content--extra-text.expanded {
    display: block;
  }

  .Home-rail-content:not(.expanded) [data-cut] ~ * {
    display: none;
  }

  .Home-rail-content-toggle {
    display: flex;
  }

  .Page--Confirmation .Home-rail-content {
    margin: 0 -16px;
    padding: 32px 16px 0;
  }

  .Home-rail-content h2 {
    color: inherit;
    font-size: var(--right-hand-rail-header-font-size-mobile);
  }
}
