.Progress progress {
  width: 100%;
  background-color: #707070;
  border: 0;
  height: 6px;
  border-radius: 20px;
}

.Progress progress::-webkit-progress-bar {
  background-color: #707070;
  border-radius: 20px;
}

.Progress progress::-webkit-progress-value {
  background-color: var(--accent-color);
  border-radius: 20px;
}

.Progress progress::-moz-progress-bar {
  background-color: var(--accent-color);
  border-radius: 20px;
}

.Progress-status {
  font-size: 12px;
  letter-spacing: 0.21px;
  color: #707070;
  margin: 0;
}
