.DocumentUpload-wrapper {
  display: flex;
  align-items: center;
}

.DocumentUpload-label {
  align-items: center;
  background-color: var(--form-browse-button-background-color);
  border-radius: var(--form-browse-button-border-radius);
  border: 2px solid var(--form-browse-button-border-color);
  color: var(--form-browse-button-color);
  cursor: pointer;
  display: flex;
  font-size: var(--form-browse-button-font-size);
  font-weight: bold;
  justify-content: center;
  height: 48px;
  margin-right: 32px;
  min-width: 232px;
  padding: 0;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.DocumentUpload-label:focus,
.DocumentUpload-label:hover {
  background-color: var(--form-browse-button-hover-background-color);
  border-color: var(--form-browse-button-hover-border-color);
  color: var(--form-browse-button-hover-color);
}

.DocumentUpload-label:focus-within {
  box-shadow: 0 0 0 2px var(--dark-primary-color);
}

.DocumentUpload-error {
  color: var(--error-color);
}

.DocumentUpload-progress {
  display: flex;
  padding: 0.75em;
  background-color: rgba(207, 212, 216, 0.2);
  margin-bottom: 8px;
}

.DocumentUpload-progress-icon {
  margin: 0 0.75em 0 0;
  text-align: center;
}

.DocumentUpload-progress-wrapper {
  flex: 1;
  overflow: hidden;
}

.DocumentUpload-progress-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.DocumentUpload-progress-filename {
  color: #333;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.41;
  letter-spacing: 0.3px;
  margin: 0 10px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DocumentUpload-progress-filename::first-letter {
  text-transform: uppercase;
}

.DocumentUpload-progress-button {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 1.7;
  color: #0961ba;
  border: 0;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0;
}

.DocumentUpload .ErrorMessage {
  margin-top: 0.5em;
}

@media only screen and (max-width: 768px) {
  .DocumentUpload-wrapper {
    align-items: flex-start;
    flex-direction: column;
  }

  .DocumentUpload-label {
    width: 100%;
    margin-right: 0;
    font-size: var(--form-browse-button-font-size-mobile);
  }

  .DocumentUpload-wrapper > div {
    margin-top: 0.5em;
  }
}
