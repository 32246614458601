.Footer {
  background-color: var(--footer-background-color);
  color: var(--footer-font-color);
  flex: 1;
  overflow: hidden;
  padding: 0 0 40px;
  text-align: center;
}

.Footer--extra-padding {
  padding-top: 24px;
}

.Footer-description {
  font-size: var(--footer-font-size);
  line-height: 20px;
  margin: 0 auto 24px;
  max-width: 1024px;
  padding: 0 68px;
  text-align: var(--footer-text-alignment);
}

.Footer p {
  margin: 0;
}

.Footer-links {
  padding: 0;
}

.Footer-links a {
  display: inline-block;
  margin: 0 8px;
}

.Footer a {
  color: var(--footer-url-font-color);
}

@media only screen and (max-width: 1024px) {
  .Footer {
    padding: 16px 16px 40px;
    text-align: center;
  }

  .Footer-description {
    font-size: var(--footer-font-size-mobile);
    line-height: 1.5;
    margin: 0 auto 40px;
    max-width: initial;
    padding: 0;
  }
}
