
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: var(--form-main-color);
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

a {
  color: var(--url-color);
  text-decoration: none;
}

:root {
  --error-color-background: #ffdede;
  --error-color-border: #e80f0f;
  --error-color-text: #e20000;
  --accent-color: #3b91f2;
  --url-color: #4286fe;
  --banner-content-alignment: center;
  --banner-color: #fff;
  --banner-font-size: 14px;
  --banner-header-font-size: 24px;
  --banner-background-color: transparent;
  --banner-confirmation-background-color: transparent;
  --banner-bottom-border: 0;
  --banner-bottom-border-color: #d8d8d8;
  --button-background-color: #2c218a;
  --button-background-disabled-color: #9492c6;
  --button-border-radius: 6px;
  --button-color: #fff;
  --button-font-size: 20px;
  --form-browse-button-background-color: #fff;
  --form-browse-button-border-color: currentColor;
  --form-browse-button-border-radius: 6px;
  --form-browse-button-color: #2c218b;
  --form-browse-button-hover-background-color: #fff;
  --form-browse-button-hover-border-color: currentColor;
  --form-browse-button-hover-color: #9492c6;
  --form-browse-button-font-size: 20px;
  --form-header-color: #333;
  --form-header-font-size: 22px;
  --form-input-background-color: #fff;
  --form-input-border-color: #000;
  --form-input-border-radius: 6px;
  --form-input-color: #000;
  --form-input-font-size: 17px;
  --form-main-color: #474747;
  --form-main-font-size: 16px;
  --form-main-border-color: #ededed;
  --right-hand-rail-header-font-size: 22px;
  --right-hand-rail-font-size: 16px;
  --right-hand-rail-background-color: #4286fe;
  --right-hand-rail-color: #fff;
  --right-hand-rail-border-radius: 6px;
  --footer-font-color: #000;
  --footer-url-font-color: #4286fe;
  --footer-font-size: 16px;
  --footer-background-color: #fff;
  --footer-text-alignment: center;

  /* mobile defaults are the same as desktop */
  --banner-font-size-mobile: 14px;
  --banner-header-font-size-mobile: 24px;
  --button-font-size-mobile: 20px;
  --form-browse-button-font-size-mobile: 20px;
  --form-header-font-size-mobile: 22px;
  --form-input-font-size-mobile: 17px;
  --form-main-font-size-mobile: 16px;
  --right-hand-rail-header-font-size-mobile: 22px;
  --right-hand-rail-font-size-mobile: 16px;
  --footer-font-size-mobile: 16px;

  /* Mapping theme variables/values to components. */
  --loader-accent-color: var(--accent-color);
  --input-label-font-size: var(--form-main-font-size);
  --input-label-font-margin: 0 0 0.5rem 0;
  --form-input-padding: 0.8rem;
  --form-input-error-color: var(--error-color-text);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
